/*
 * (C) Copyright  2024 DigitalLab.  Confidential computer software.
 * Valid license from DigitalLab required for possession, use or copying.
 * Consistent with FAR 12.211 and 12.212, Commercial Computer Software,
 * Computer Software Documentation, and Technical Data for Commercial Items are
 * licensed to the U.S. Government under vendor's standard commercial license.
 */

import './App.css';
import { MainArea } from './components/MainArea';
import {GoogleOAuthProvider} from "@react-oauth/google";

/**
 * Main component, this is the entry point of the application.
 * @returns {JSX.Element} main component
 * @constructor
 */
function App() {
  return (
      <GoogleOAuthProvider clientId="707419233449-rnrcr727j2a83ob0r981igaftdo1gpdb">
          <MainArea />
      </GoogleOAuthProvider>
  );
}

export default App;
