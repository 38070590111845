/*
 * (C) Copyright  2024 DigitalLab.  Confidential computer software.
 * Valid license from DigitalLab required for possession, use or copying.
 * Consistent with FAR 12.211 and 12.212, Commercial Computer Software,
 * Computer Software Documentation, and Technical Data for Commercial Items are
 * licensed to the U.S. Government under vendor's standard commercial license.
 */

import {Link} from "react-router-dom";

export const Privacy = () => {
    return (
        <div className="main-area-home">
            <nav className="top-menu-home">
                <div className="top-menu-home-content">
                    <h1 className="logo-home">PPCBot</h1>
                </div>
                <div className="top-menu-options">
                    <Link to="/home"><p>Home</p></Link>
                </div>
            </nav>
            <div className="home-section">
                <h1> Privacy Policy</h1>
                <strong>Effective Date: November 12, 2024</strong>
                <h3>1. Introduction</h3>
                <p className="home-text">At PPC Bot, accessible from https://ppcbot.tech, we prioritize the privacy of our users. This Privacy
                    Policy outlines the types of information we collect, how we use it, and the measures we take to
                    protect your data.</p>
                <h3>2. Information We Collect</h3>
                <p className="home-text">We collect the following types of information:</p>
                <p className="home-text">Personal Identification Information: Name, email address, and other contact details.</p>
                <p className="home-text">Usage Data: Details of your interactions with our app, including IP addresses, device information,
                    and browsing patterns.</p>
                <h3>3. How We Use Your Information</h3>
                <p className="home-text">The information we collect is used to:</p>
                <p className="home-text">Provide and maintain our services.</p>
                <p className="home-text">Improve user experience.</p>
                <p className="home-text">Communicate with you, including sending updates and promotional materials.</p>
                <h3>4. Data Sharing and Disclosure</h3>
                <p className="home-text">We do not sell or rent your personal information to third parties. We may share information with:</p>
                <p className="home-text">Service Providers: Third-party vendors who assist in operating our services.</p>
                <p className="home-text">Legal Requirements: When required by law or to protect our rights.</p>
                <h3>5. Data Security</h3>
                <p className="home-text">We implement industry-standard security measures to protect your data. However, no method of
                    transmission over the internet is entirely secure, and we cannot guarantee absolute security.</p>
                <h3>6. Your Rights</h3>
                <p className="home-text">Depending on your jurisdiction, you may have the right to:</p>
                <p className="home-text">Access, correct, or delete your personal information.</p>
                <p className="home-text">Opt out of marketing communications.</p>
                <h3>7. Changes to This Policy</h3>
                <p className="home-text">We may update this Privacy Policy periodically. Any changes will be posted on this page with an
                    updated effective date.</p>
                <h3>8. Contact Us</h3>
                <p className="home-text">For questions or concerns about this Privacy Policy, please contact us at contact@ppcbot.tech.</p>
                <h1>Terms and Conditions</h1>
                <p className="home-text">Effective Date: November 12, 2024</p>
                <h3>1. Introduction</h3>
                <p className="home-text">These Terms and Conditions govern your use of the PPC Bot app. By accessing or using our app, you
                    agree to comply with these terms.</p>
                <h3>2. Use of the App</h3>
                <p className="home-text">You agree to use the app only for lawful purposes and in accordance with our guidelines.</p>
                <h3>3. User Responsibilities</h3>
                <p className="home-text">You are responsible for maintaining the confidentiality of your account information and for all
                    activities under your account.</p>
                <h3>4. Prohibited Activities</h3>
                <p className="home-text">You must not:</p>
                <p className="home-text">Engage in any unlawful activities.</p>
                <p className="home-text">Attempt to interfere with the app&apos;s functionality.</p>
                <h3>5. Termination</h3>
                <p className="home-text">We reserve the right to suspend or terminate your access if you violate these terms.</p>
                <h3>6. Limitation of Liability</h3>
                <p className="home-text">Our liability is limited to the maximum extent permitted by law. We are not liable for any indirect
                    or consequential damages arising from your use of the app.</p>
                <h3>7. Governing Law</h3>
                <p className="home-text">These terms are governed by the laws of [Your Jurisdiction].</p>
                <h3>8. Changes to These Terms</h3>
                <p className="home-text">We may update these Terms and Conditions periodically. Any changes will be posted on this page with
                    an updated effective date.</p>
                <h3>9. Contact Us</h3>
                <p className="home-text">For questions or concerns about these Terms and Conditions, please contact us at
                    contact@ppcbot.tech.</p>
                <p className="home-text">Please review these documents carefully to ensure they align with your specific business practices
                    and legal requirements.</p>
            </div>
        </div>
    )
}
