/*
 * (C) Copyright  2024 DigitalLab.  Confidential computer software.
 * Valid license from DigitalLab required for possession, use or copying.
 * Consistent with FAR 12.211 and 12.212, Commercial Computer Software,
 * Computer Software Documentation, and Technical Data for Commercial Items are
 * licensed to the U.S. Government under vendor's standard commercial license.
 */

import './ChatListItem.css';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

/**
 * Chat list item, this is where the chat name should be displayed, and the user can click on it to open the chat.
 * @param chat chat object with the chat name
 * @returns {JSX.Element} chat list item
 * @constructor
 */
export const ChatListItem = ({ chat }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/c/${chat.id}`);
  };

  return (
    <div className="chat-list-item" onClick={handleClick}>
      <h4>{chat.name}</h4>
    </div>
  );
};

ChatListItem.propTypes = {
  chat: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};
