/*
 * (C) Copyright  2024 DigitalLab.  Confidential computer software.
 * Valid license from DigitalLab required for possession, use or copying.
 * Consistent with FAR 12.211 and 12.212, Commercial Computer Software,
 * Computer Software Documentation, and Technical Data for Commercial Items are
 * licensed to the U.S. Government under vendor's standard commercial license.
 */

import PropTypes from 'prop-types';
import { SendButton } from './SendButton';

/**
 * Input section component, this is where the user can type a message and send it.
 * @param message message to be sent
 * @param handleInputChange function to handle input change
 * @param handleKeyPress function to handle key press
 * @returns {JSX.Element} input section
 * @constructor
 */
export const InputSection = ({ message, handleInputChange, handleKeyPress }) => {
  return (
    <div className="input-section">
      <input
        value={message}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
        type="text"
        placeholder="Type a message..."
      ></input>
      <SendButton message={message}></SendButton>
    </div>
  );
};

InputSection.propTypes = {
  message: PropTypes.string,
  handleInputChange: PropTypes.func,
  handleKeyPress: PropTypes.func,
};
