/*
 * (C) Copyright  2024 DigitalLab.  Confidential computer software.
 * Valid license from DigitalLab required for possession, use or copying.
 * Consistent with FAR 12.211 and 12.212, Commercial Computer Software,
 * Computer Software Documentation, and Technical Data for Commercial Items are
 * licensed to the U.S. Government under vendor's standard commercial license.
 */

import PropTypes from 'prop-types';

/**
 * Chat message component, this is where the chat messages should be displayed.
 * @param message message object
 * @returns {JSX.Element} chat message
 * @constructor
 */
export const ChatMessage = ({message}) => {
    return (
        message.role  === 'user' ?
            (
                <div key={message.id} className={'chat-container typewriter ' + message.role}>
                    <p>{message.message}</p>
                </div>
            ) : (
                <div key={message.id} className={'chat-container ' + message.role}
                                dangerouslySetInnerHTML={{__html: message.message}}/>
            )
    );
};

ChatMessage.propTypes = {
    message: PropTypes.shape({
        id: PropTypes.string, message: PropTypes.string, role: PropTypes.string,
    }),
};
