/*
 * (C) Copyright  2024 DigitalLab.  Confidential computer software.
 * Valid license from DigitalLab required for possession, use or copying.
 * Consistent with FAR 12.211 and 12.212, Commercial Computer Software,
 * Computer Software Documentation, and Technical Data for Commercial Items are
 * licensed to the U.S. Government under vendor's standard commercial license.
 */

/**
 *  EmptyChatMessage component
 * @returns {JSX.Element} EmptyChatMessage component
 * @constructor
 */
export const EmptyChatMessage = () => {
  return (
    <div className="chat-container">
      <p className="centered-text">No messages yet</p>
    </div>
  );
};
