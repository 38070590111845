/*
 * (C) Copyright  2024 DigitalLab.  Confidential computer software.
 * Valid license from DigitalLab required for possession, use or copying.
 * Consistent with FAR 12.211 and 12.212, Commercial Computer Software,
 * Computer Software Documentation, and Technical Data for Commercial Items are
 * licensed to the U.S. Government under vendor's standard commercial license.
 */

import PropTypes from 'prop-types';

/**
 * Send button component, this is where the send button is displayed
 * @param message message to be sent
 * @returns {JSX.Element} send button
 * @constructor
 */
export const SendButton = ({ message }) => {
  return (
    <button className={(message.length > 0 ? 'active-button ' : '') + 'button-send'}>
      <span className="material-symbols-outlined">send</span>
    </button>
  );
};

SendButton.propTypes = {
  message: PropTypes.string,
};
