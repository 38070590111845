/*
 * (C) Copyright  2024 DigitalLab.  Confidential computer software.
 * Valid license from DigitalLab required for possession, use or copying.
 * Consistent with FAR 12.211 and 12.212, Commercial Computer Software,
 * Computer Software Documentation, and Technical Data for Commercial Items are
 * licensed to the U.S. Government under vendor's standard commercial license.
 */

import './MainArea.css';

/**
 * Top menu for the main area, this is where the title of the chatbot is displayed. Also, this is where the username
 * should be displayed.
 * @returns {JSX.Element}
 * @constructor
 */
export const TopMenuMainArea = () => {
  return (
    <nav className="top-menu-main-area">

    </nav>
  );
};
