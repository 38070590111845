/*
 * (C) Copyright  2024 DigitalLab.  Confidential computer software.
 * Valid license from DigitalLab required for possession, use or copying.
 * Consistent with FAR 12.211 and 12.212, Commercial Computer Software,
 * Computer Software Documentation, and Technical Data for Commercial Items are
 * licensed to the U.S. Government under vendor's standard commercial license.
 */

import { ChatList } from './chat/ChatList';
import PropTypes from 'prop-types';
import {useEffect, useState} from "react";
import {jwtDecode} from "jwt-decode";

/**
 * Side menu component, this is where the chat list should be displayed.
 * @param chatList list of chats
 * @returns {JSX.Element} side menu
 * @constructor
 */
export const SideMenu = ({ chatList, isLoading, token, fetchMessages }) => {
    const [decodedToken, setDecodedToken] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        if (token === "") {
            setIsLoggedIn(false);
            console.log("Empty token: Side menu")
            return
        }
        fetchMessages();
        const decoded = jwtDecode(token);
        setDecodedToken(decoded);
        console.log(decoded);
    }, [token]);

  return (
   <>
        { isLoggedIn ? <>
                <nav className="side-menu">
                    <ChatList chatsList={chatList} isLoading={isLoading}></ChatList>
                    {
                        decodedToken ?
                            <div className="name-wrapper">
                                <p>{decodedToken.name}</p>
                                <img src={decodedToken.picture} alt="profile" className="profile-picture"></img>
                            </div>
                            : <p></p>
                    }
                </nav>
        </> : <></> }
   </>
  );
};

SideMenu.propTypes = {
  chatList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  isLoading: PropTypes.bool,
    token: PropTypes.string,
    fetchMessages: PropTypes.func
};
