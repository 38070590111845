/*
 * (C) Copyright  2024 DigitalLab.  Confidential computer software.
 * Valid license from DigitalLab required for possession, use or copying.
 * Consistent with FAR 12.211 and 12.212, Commercial Computer Software,
 * Computer Software Documentation, and Technical Data for Commercial Items are
 * licensed to the U.S. Government under vendor's standard commercial license.
 */

import {useEffect, useRef, useState} from 'react';
import { ChatMessage } from './ChatMessage';
import { EmptyChatMessage } from './EmptyChatMessage';
import { InputSection } from './InputSection';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { jwtDecode } from "jwt-decode";

/**
 * Chat section component, this is where the chat messages should be displayed.
 * @param handleReload function to reload the chat list
 * @returns {JSX.Element} chat section
 * @constructor
 */
export const ChatSection = ({ handleReload, token }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isNewConversation, setIsNewConversation] = useState(false);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (id) {
      fetchMessages(id, () => {
        navigate('/');
      });
    } else {
      console.log('New conversation');
      setMessages([
      ]);
      setIsLoading(false);
      setIsNewConversation(true);
    }
  }, [id]);

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (token === "") {
      console.log("Empty token: Chat section")
      navigate('/home');
    }
    const decoded = jwtDecode(token);
    console.log(decoded);
  }, [token]);

  const postMessage = async (conversationId, message) => {
    try {
      await axios.post('http://127.0.0.1:5000/chats/' + conversationId, message);
      fetchMessages(id, () => {});
    } catch (error) {
      console.error('Error posting message:', error);
    }
  };

  const createConversation = async (messages) => {
    try {
      const response = await axios.post('http://127.0.0.1:5000/chats', messages);
      handleReload();
      navigate(`/c/${response.data.id}`);
    } catch (error) {
      console.error('Error posting message:', error);
    }
  };

  const handleSendMessage = (message) => {
    setMessages([...messages, message]);
    if (isNewConversation) {
      createConversation([...messages, message]);
    } else {
      postMessage(id, message);
    }
  };

  const fetchMessages = async (id, onError) => {
    try {
      console.log(id)
      const response = await axios.get('http://127.0.0.1:5000/chats/' + id);
      setMessages(response.data);
      setIsLoading(false);
    } catch (error) {
      onError(error);
    }
  };

  const [message, setMessage] = useState('');

  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage({ message: message, role: 'user' });
      setMessage('');
    }
  };

  return (
    <div className="chat-section">
      {isLoading ? (
        <div className="loader-wrapper">
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <>
          <div className="chat">
            {messages.length === 0 ? (
              <EmptyChatMessage />
            ) : (
              messages.map((message, index) => {
                return <ChatMessage message={message} key={index} />;
              })
            )}
          </div>
          <InputSection
            handleInputChange={handleInputChange}
            handleKeyPress={handleKeyPress}
            message={message}
          />
        </>
      )}
    </div>
  );
};

ChatSection.propTypes = {
  handleReload: PropTypes.func,
  token: PropTypes.string
};
